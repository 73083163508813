import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from 'react';
import { TextField } from '@mui/material';
import { isValid } from 'date-fns';
import { CustomDatePickerProps } from './custom-date-picker';
import { useTranslation } from 'react-i18next';
import { formatDateToLocale, localeToDate } from 'i18n';

export type CustomDatePickerWrapperProps = CustomDatePickerProps & {
    datePickerButton: React.ReactNode;
};

export const CustomDatePickerWrapper = ({
    variable,
    label,
    defaultValue = '',
    className = '',
    datePickerButton,
    onChange,
}: CustomDatePickerWrapperProps) => {
    const [controlValue, setControlValue] = useState<string>(defaultValue || '');
    const { t } = useTranslation();
    let defaultDate: Date | null = new Date(formatDateToLocale(new Date()));
    const tempDate = localeToDate(controlValue);
    if (isValid(tempDate)) defaultDate = tempDate;

    const onDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setControlValue(event.target.value);
        if (onChange) onChange(variable || '', event.target.value);
    };

    const onDateLostFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        const date = new Date(event.target.value);
        if (isValid(date) && controlValue !== event.target.value) {
            setDate(date);
        }
    };

    const onDateSelect = (date: Date) => {
        if (isValid(date)) {
            setDate(date);
        }
    };
    const setDate = (date: Date) => {
        const strDate = formatDateToLocale(date, undefined, new Date(controlValue));
        setControlValue(strDate);
        if (onChange) onChange(variable || '', strDate);
    };

    return (
        <div className="flex relative">
            <TextField
                label={label}
                size="small"
                className={`w-full ${className}`}
                value={controlValue}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                    maxLength: 50,
                }}
                InputProps={{
                    style: {
                        paddingRight: 40,
                    },
                }}
                onChange={onDateChange}
                onBlur={onDateLostFocus}
            />

            <div className="absolute right-1 top-0">
                <DatePicker
                    selected={defaultDate}
                    onChange={onDateSelect}
                    customInput={datePickerButton}
                    timeInputLabel={t('Time')}
                    peekNextMonth
                    showTimeInput
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
            </div>
        </div>
    );
};
