import { Paper } from '@mui/material';
import { AddFieldsInstructions } from './add-fields-instructions';
import { FormTitle } from './form-title';
import { StandardFormFields } from './standard-form-fields';
import { DraggableFormField } from './draggable-form-field';
import { CustomIconButton } from 'components/custom-controls';
import { usePlugins } from 'custom-hooks/use-plugins/use-plugins';
import pinIcon from 'components/app-assets/icon-pinned.svg';
import { useTranslation } from 'react-i18next';

type FormFieldsExpandedPanelProps = {
    onPinButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export const FormFieldsExpandedPanel = ({ onPinButtonClick }: FormFieldsExpandedPanelProps) => {
    const { t } = useTranslation();
    const { plugins } = usePlugins();

    const formFields = plugins.map((p) => <DraggableFormField plugin={p} key={p.id} />);
    return (
        <Paper elevation={3} className="flex flex-col w-[330px] overflow-hideen bg-transparent">
            <div className="flex flex-row bg-theme-pinpanel text-theme-pinpanel-text">
                <div className="flex-1">
                    <FormTitle />
                </div>
                <CustomIconButton src={pinIcon} tooltip={t('Unpin to hide Fields panel.')} onClick={onPinButtonClick} />
            </div>
            <div className="flex flex-col h-screen overflow-y-auto bg-white">
                <AddFieldsInstructions />
                <StandardFormFields fields={formFields} />
            </div>
        </Paper>
    );
};
