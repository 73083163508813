import { Button } from '@mui/material';
import { useAppSnackbarApi } from 'custom-hooks/use-app-snackbar-api';
import { useAppLoadingApi } from 'custom-hooks/use-app-loading-api';
import { useHttpSaveForm } from 'custom-hooks/use-http-save-form';
import { useSleep } from 'custom-hooks/use-sleep';
import { useAppArgs } from 'custom-hooks/use-app-args';
import { useTranslation } from 'react-i18next';
import { useFormActions } from 'custom-hooks/use-form-actions';

export const AppActions = () => {
    const { redirectUrl } = useAppArgs();
    const { saveForm } = useHttpSaveForm();
    const { setNewFormId } = useFormActions();
    const { showLoading, hideLoading } = useAppLoadingApi();
    const { showSuccessMessage, showErrorMessage } = useAppSnackbarApi();
    const { sleep } = useSleep();
    const { t } = useTranslation();

    const onSave = async (canClose: boolean) => {
        showLoading({ title: t('Saving Form'), text: t('Please wait') + ' . . .' });
        const response = await saveForm();

        // sometimes above request executes so fast and removing the loading componenet immediatly creating a flickering effect.
        // so add one second delay to avoid the flickering.
        await sleep(1);
        hideLoading();

        if (response.error && response.error.message) {
            console.log('Failed to save form: ', response);
            showErrorMessage({
                title: t('Failed to save the form!'),
                text: `${response.error.message} ${
                    response.error.code ? ' (' + t('code') + ': ' + response.error.code + ')' : ''
                }`,
            });
            return;
        }

        showSuccessMessage({
            title: t('Congratulations!'),
            text: t('The Form has been successfully saved.'),
        });

        if (!canClose) {
            if (response.data?.id) {
                setNewFormId(response.data?.id);
            }
            return;
        }

        await sleep(1);
        backToFormsManagement();
    };

    const onCancel = () => {
        backToFormsManagement();
    };

    const backToFormsManagement = () => {
        window.location.replace(redirectUrl);
    };

    return (
        <div className="flex ml-default mr-default mb-default p-actions bg-white">
            <div className="flex-1"></div>
            <div className="space-x-6">
                <Button variant="outlined" onClick={onCancel}>
                    {t('Cancel')}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        onSave(false);
                    }}>
                    {t('Save')}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        onSave(true);
                    }}>
                    {t('Save and Close')}
                </Button>
            </div>
        </div>
    );
};
