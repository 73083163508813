import { Base64 } from 'js-base64';
import { TIME_ZONE } from 'timezone';

export const useAppArgs = () => {
    const search = document.location.search;
    let formId = '',
        groupId = '',
        tenantName = '',
        redirectUrl = '',
        tenantUrl = '',
        tenantSlug = '',
        domain = '',
        fallbackDomain = '',
        tz = '',
        plan = 'stc',
        isStratus = false;

    const hostUrl = window.location.origin;

    if (search) {
        const decodedQs = Base64.decode(decodeURIComponent(search.replace('?', '')));
        const queryParams = new URLSearchParams(decodedQs);
        tenantName = queryParams.get('tenant') || '';
        formId = queryParams.get('id') || '';
        groupId = queryParams.get('group') || '';
        redirectUrl = queryParams.get('redirect_url') || '';
        tz = queryParams.get('tz') || '';
        plan = (queryParams.get('plan') || 'stc').toLocaleLowerCase().trim();
        isStratus = 'stratus' === plan;

        const { host } = new URL(redirectUrl);
        const tokens = host.split('.');
        domain = `${tokens[tokens.length - 2]}.${tokens[tokens.length - 1]}`;
        tenantSlug = tokens.length > 3 ? tokens[0] : tenantName;
        tenantUrl = host;
        if (tz) TIME_ZONE.key = tz;
    }

    if (domain) {
        // use a fallbackm domain for the Forms Backend API's in case the request is from any one of the following domains.
        const altDomains: Record<string, string> = {
            'scantripcloud.lol': 'stratus.lol',
            'scantripcloud.xyz': 'dodgestrat.us',
            'dispatcherstratus.com': 'scantripcloud.com',
        };
        fallbackDomain = altDomains[domain.toLowerCase()] || domain;
    }

    return {
        formId,
        groupId,
        redirectUrl,
        tenantSlug,
        hostUrl,
        tenantUrl,
        domain,
        fallbackDomain,
        search,
        plan,
        isStratus,
    } as const;
};
