import { Typography } from '@mui/material';
import { FormFieldComponentProps } from 'contracts/forms';
import {
    CustomAccordion,
    CustomAddHelpText,
    CustomCheckbox,
    CustomTextField,
    CustomDatePicker,
    CustomDropDown,
    CustomVariableNameField,
} from 'components/custom-controls';
import { useFormFieldDate } from './use-form-field-date';
import { dateTimeFormats } from './form-field-date-config';
import dateIcon from './assets/icon-form-field-datetime.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatISOtoLocale } from 'i18n';

export const FormFieldDateProperties = (props: FormFieldComponentProps) => {
    const { config, onCustomTextFieldChange, onCustomCheckboxChange } = useFormFieldDate(props);
    const [defaultToNow, setDefaultToNow] = useState<boolean>(config['defaultToNow']);
    const { t } = useTranslation();

    const onDefaultToNowChange = (variable: string, value: boolean) => {
        onCustomCheckboxChange(variable, value);
        setDefaultToNow(value);
    };

    return (
        <div className="flex flex-col">
            <CustomVariableNameField
                variable="variable"
                label={t('Variable Name')}
                defaultValue={config['variable']}
                maxLength={50}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
            <CustomTextField
                variable="title"
                label={t('Friendly Name (Viewable)')}
                defaultValue={config['title']}
                maxLength={100}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
            <CustomDropDown
                variable="returnFormat"
                label={t('Return Format')}
                defaultValue={config['returnFormat']}
                options={dateTimeFormats}
                className="mb-4"
                onChange={onCustomTextFieldChange}
            />
            <Typography variant="caption" className="font-semibold">
                {t('Default Value')}
            </Typography>
            <CustomCheckbox
                variable="defaultToNow"
                label={t('Default to now')}
                defaultValue={defaultToNow}
                onChange={onDefaultToNowChange}
                className=" mb-4"
            />
            <div className={` ${defaultToNow ? 'h-0 overflow-hidden' : ''}`}>
                <CustomDatePicker
                    variable="defaultValueLocale"
                    label={t('Add Date and Time')}
                    defaultValue={formatISOtoLocale(config['defaultValue'])}
                    iconTooltip={t('Select a Date')}
                    className="mb-4"
                    onChange={onCustomTextFieldChange}
                    iconSrc={dateIcon}
                />
            </div>
            <Typography variant="caption" className="font-semibold mb-4">
                {t('Default Range')}
            </Typography>
            <CustomDatePicker
                variable="minValueLocale"
                label={t('Min Value')}
                defaultValue={formatISOtoLocale(config['minValue'])}
                iconTooltip={t('Select a Date')}
                className="mb-4"
                onChange={onCustomTextFieldChange}
                iconSrc={dateIcon}
            />
            <CustomDatePicker
                variable="maxValueLocale"
                label={t('Max Value')}
                defaultValue={formatISOtoLocale(config['maxValue'])}
                iconTooltip={t('Select a Date')}
                className="mb-4"
                onChange={onCustomTextFieldChange}
                iconSrc={dateIcon}
            />
            <CustomAccordion title={t('Actions')} expanded={true}>
                <div className="flex flex-col">
                    <CustomCheckbox
                        variable="required"
                        label={t('Required')}
                        defaultValue={config['required']}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomCheckbox
                        variable="readonly"
                        label={t('Read only')}
                        defaultValue={config['readonly']}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomCheckbox
                        variable="excludeFromMetadata"
                        label={t('Exclude from metadata')}
                        defaultValue={config['excludeFromMetadata']}
                        onChange={onCustomCheckboxChange}
                    />
                    <CustomAddHelpText
                        checkboxVariable="showHelp"
                        checkboxLabel={t('Add help text')}
                        checkboxDefaultValue={config['showHelp']}
                        onCheckboxChange={onCustomCheckboxChange}
                        txtVariable="helpText"
                        txtLabel={t('Edit help text')}
                        txtDefaultValue={config['helpText'] || ''}
                        onTxtChange={onCustomTextFieldChange}
                    />
                </div>
            </CustomAccordion>
        </div>
    );
};
