import { MfpPanelItem } from './mfp-panel-item';
import mfpIcon from './assets/icon-mfp-image-color.svg';

type MfpPanelIconProps = {
    item: MfpPanelItem;
    selectMfpPanelIcon: (id: string) => void;
};

export const MfpPanelIcon = ({ item, selectMfpPanelIcon }: MfpPanelIconProps) => {
    let rootElementCss = 'flex flex-col m-0 rounded-md w-[116px]';
    let titleElementCss = 'flex pl-2 pr-2 pt-2 pb-0 justify-center items-center break-all text-[0.60rem]';
    if (item.selected) {
        rootElementCss += ' bg-theme-600';
        titleElementCss += ' text-white';
    }

    return (
        <div
            className={rootElementCss}
            onClick={() => {
                selectMfpPanelIcon(item.id);
            }}>
            <div className="pt-1">
                <img src={mfpIcon} alt={item.title} className="w-[112px] pl-1" />
            </div>
            <div className="mx-auto mb-0.5">
                <div className={titleElementCss}>{item.title}</div>
            </div>
        </div>
    );
};
