import { Typography } from '@mui/material';
import {
    CustomDialog,
    CustomDialogActions,
    CustomDialogBody,
    CustomDialogTitle,
    CustomIconButton,
} from 'components/custom-controls';
import CloseIcon from '@mui/icons-material/Close';
import { FormFieldDropdownOption } from './form-field-dropdown-config';
import { DropdownOptionsEditor } from './dropdown-options-editor';
import { DropdownOptionsDialogHeader } from './dropdown-options-dialog-header';
import { DropdownOptionsDialogActions } from './dropdown-options-dialog-actions';
import { DropdownOptionsEditorContextProvider } from './dropdown-options-editor-context-provider';
import { useTranslation } from 'react-i18next';

export type DropdownOptionsDialogProps = {
    options: FormFieldDropdownOption[];
    multiSelect: boolean;
    show: boolean;
    onSave: (options: FormFieldDropdownOption[]) => void;
    onCancel: () => void;
};

export const DropdownOptionsDialog = (props: DropdownOptionsDialogProps) => {
    const { t } = useTranslation();
    if (!props.show) {
        return <></>;
    }

    return (
        <CustomDialog show={props.show}>
            <DropdownOptionsEditorContextProvider
                initialState={{
                    options: JSON.parse(JSON.stringify(props.options)),
                    multiSelect: props.multiSelect,
                }}
                initialCallbacks={{ onSave: props.onSave, onCancel: props.onCancel, dispatchOnSave: () => null }}>
                <CustomDialogTitle>
                    <div className="flex flex-row">
                        <div className="flex-1">
                            <Typography variant="h6" className="flex flex-1 text-base pl-2 mt-1">
                                {t('Drop-Down List Options')}
                            </Typography>
                        </div>
                        <CustomIconButton
                            className="-mt-1.5 -mr-1"
                            tooltip={t('Close')}
                            lightRipple
                            onClick={props.onCancel}>
                            <CloseIcon className="text-theme-titlebar-text" />
                        </CustomIconButton>
                    </div>
                </CustomDialogTitle>
                <CustomDialogBody>
                    <div>
                        <Typography variant="caption">{t('Add labels and values to drop-down menu.')}</Typography>
                    </div>
                    <div className="flex flex-col mt-4 border border-solid rounded border-gray-600">
                        <DropdownOptionsDialogHeader />
                        <DropdownOptionsEditor />
                    </div>
                </CustomDialogBody>
                <CustomDialogActions>
                    <DropdownOptionsDialogActions />
                </CustomDialogActions>
            </DropdownOptionsEditorContextProvider>
        </CustomDialog>
    );
};
