export const stcTwTheme = {
    color50: '#f2f2fb',
    color100: '#f2eff9',
    color200: '#d5d4f1',
    color300: '#ddd5f2',
    color400: '#a89ddc',
    color500: '#9584d0',
    color600: '#7d61bc',
    color700: '#745aa8',
    color800: '#794DA6',
    color900: '#231231',
    color950: '#262626',
    sidebar: '#262626',
    sidebarText: '#ffffff',
    sidebarTopMargin: '0px',
    headerText: '#ffffff',
    pagetitle: '#FFFFFF',
    pagetitleText: '#323232',
    pagetitlePadding: '2px',
    pinpanel: '#FFFFFF',
    pinpanelText: '#323232',
    unpinpanel: '#ddd5f2',
    unpinpanelText: '#323232',
    errorspanel: '#231231',
    errorspanelText: '#ffffff',
    titlebar: '#231231',
    titlebarText: '#ffffff',
    footer: '#EBEBEB',
    footerText: '#000000',
    defaultMargin: '16px',
    extraMargin: '0px',
    defaultPadding: '16px',
    actionsPadding: '0px',
    appLogo: "url('/assets/images/logo-stc.svg')",
    logoWidth: '240px',
    logoHeight: '44px',
    bgPattern: `'none'`,
    headerPattern:
        "linear-gradient(90deg, #262626 0%, #534C64 74%, #665984 91%, #7D61BC 100%), url('/assets/images/stc-header.png')",
};
