import { useRef } from 'react';
import { FormFieldComponentProps } from 'contracts/forms';
import { FormFieldDateConfig, defaultFormFieldDateConfig } from './form-field-date-config';
import { convertToUtc } from 'i18n';

export const useFormFieldDate = ({ field, updateField }: FormFieldComponentProps) => {
    const configFromField = field.config as FormFieldDateConfig;
    const config = {
        ...defaultFormFieldDateConfig,
        ...configFromField,
    };
    console.log('config', config);
    const configRef = useRef<FormFieldDateConfig>(config);

    const onCustomTextFieldChange = (variable: string, value: string) => {
        updateConfig(variable as keyof FormFieldDateConfig, value);
    };

    const onCustomCheckboxChange = (variable: string, value: boolean) => {
        updateConfig(variable as keyof FormFieldDateConfig, value);
    };

    const updateConfig = <K extends keyof FormFieldDateConfig>(key: K, value: FormFieldDateConfig[K]) => {
        const updatedConfig = {
            ...configRef.current,
        };
        updatedConfig[key] = value;
        switch (key) {
            case 'defaultValueLocale': {
                updatedConfig['defaultValue'] = convertToUtc(updatedConfig['defaultValueLocale']);
                break;
            }
            case 'minValueLocale': {
                updatedConfig['minValue'] = convertToUtc(updatedConfig['minValueLocale']);
                break;
            }
            case 'maxValueLocale': {
                updatedConfig['maxValue'] = convertToUtc(updatedConfig['maxValueLocale']);
                break;
            }
        }
        console.log('updatedConfig', updatedConfig);
        configRef.current = updatedConfig;

        const modifiedField = {
            ...field,
        };
        modifiedField.config = updatedConfig;
        updateField(modifiedField);
    };

    return {
        config,
        onCustomTextFieldChange,
        onCustomCheckboxChange,
    } as const;
};
