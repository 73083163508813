import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { CustomHelpIcon } from 'components/custom-controls';
import { FormFieldComponentProps } from 'contracts/forms';
import { FormFieldDateConfig } from './form-field-date-config';
import { useTranslation } from 'react-i18next';
import dateIcon from './assets/icon-form-field-datetime.svg';
import { formatISOtoLocale } from 'i18n';

export const FormFieldDatePreview = ({ field }: FormFieldComponentProps) => {
    const config = field.config as FormFieldDateConfig;
    const { t } = useTranslation();

    return (
        <div className="flex flex-row">
            <FormControl size="small" variant="outlined" className="flex-1">
                <InputLabel htmlFor="outlined-adornment-date" shrink sx={{ backgroundColor: 'white' }}>
                    {`${config.title} ${config.required ? '*' : ''}`}
                </InputLabel>
                <OutlinedInput
                    id="outlined-adornment-date"
                    type="text"
                    value={config.defaultToNow ? t('Now') : formatISOtoLocale(config.defaultValue)}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton aria-label="date" edge="end">
                                <img src={dateIcon} alt="Date" />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            {config.showHelp && <CustomHelpIcon className="ml-3" />}
        </div>
    );
};
