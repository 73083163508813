import { useState } from 'react';
import { Typography } from '@mui/material';
import { FormFieldDropdownOption } from './form-field-dropdown-config';
import { DropdownOptionsDialog } from './dropdown-options-dialog';
import { DropdownOptionsItem } from './dropdown-options-item';
import { CustomIconButton } from 'components/custom-controls';
import editIcon from './assets/white-edit-pencil.svg';
import { useTranslation } from 'react-i18next';

type DropdownOptionsProps = {
    options: FormFieldDropdownOption[];
    multiSelect: boolean;
    onDropdownOptionsChange: (value: FormFieldDropdownOption[]) => void;
};

export const DropdownOptions = ({ options, multiSelect, onDropdownOptionsChange }: DropdownOptionsProps) => {
    const [showOptions, setShowOptions] = useState(false);
    const { t } = useTranslation();

    const toggleOptionsDialog = () => {
        setShowOptions((flag) => !flag);
    };

    const onSave = (updatedOptions: FormFieldDropdownOption[]) => {
        if (onDropdownOptionsChange) onDropdownOptionsChange(updatedOptions);
        toggleOptionsDialog();
    };

    const dorpdownItems = options.filter((opt) => opt.show).map((opt) => <DropdownOptionsItem {...opt} key={opt.id} />);

    return (
        <>
            <div className="flex flex-col border border-solid rounded border-gray-600">
                <div className="grid grid-cols-2 p-1 rounded-t bg-theme-600 text-white">
                    <div className="pl-1 pb-1 items-center justify-center">
                        <Typography variant="caption">{t('Label')}</Typography>
                    </div>
                    <div className="flex flex-row items-center justify-center">
                        <Typography variant="caption" className="flex flex-1">
                            {t('Value')}
                        </Typography>
                        <CustomIconButton
                            src={editIcon}
                            lightRipple
                            tooltip={t('Edit drop-down list options')}
                            onClick={toggleOptionsDialog}
                        />
                    </div>
                </div>
                {dorpdownItems}
            </div>
            <DropdownOptionsDialog
                show={showOptions}
                options={options}
                multiSelect={multiSelect}
                onSave={onSave}
                onCancel={toggleOptionsDialog}
            />
        </>
    );
};
