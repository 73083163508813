import { stcTwTheme } from './stc-tw-theme';
import { stratusTwTheme } from './stratus-tw-theme';

export interface TwTheme {
    [key: string]: string;
}

export interface TwThemes {
    [key: string]: TwTheme;
}

export interface TwMappedTheme {
    [key: string]: string | null;
}

export const twThemes: TwThemes = {
    stc: stcTwTheme,
    stratus: stratusTwTheme,
};

export const mapTwTheme = (variables: TwTheme): TwMappedTheme => {
    return {
        '--color-50': variables.color50 || '',
        '--color-100': variables.color100 || '',
        '--color-200': variables.color200 || '',
        '--color-300': variables.color300 || '',
        '--color-400': variables.color400 || '',
        '--color-500': variables.color500 || '',
        '--color-600': variables.color600 || '',
        '--color-700': variables.color700 || '',
        '--color-800': variables.color800 || '',
        '--color-900': variables.color900 || '',
        '--color-950': variables.color950 || '',
        '--sidebar': variables.sidebar || '',
        '--sidebar-text': variables.sidebarText || '',
        '--sidebar-top-margin': variables.sidebarTopMargin || '',
        '--header-text': variables.headerText || '',
        '--pagetitle': variables.pagetitle || '',
        '--pagetitle-text': variables.pagetitleText || '',
        '--pagetitle-padding': variables.pagetitlePadding || '',
        '--pinpanel': variables.pinpanel || '',
        '--pinpanel-text': variables.pinpanelText || '',
        '--unpinpanel': variables.unpinpanel || '',
        '--unpinpanel-text': variables.unpinpanelText || '',
        '--errorspanel': variables.errorspanel || '',
        '--errorspanel-text': variables.errorspanelText || '',
        '--titlebar': variables.titlebar || '',
        '--titlebar-text': variables.titlebarText || '',
        '--footer': variables.footer || '',
        '--footer-text': variables.footerText || '',
        '--default-margin': variables.defaultMargin || '',
        '--extra-margin': variables.extraMargin || '',
        '--default-padding': variables.defaultPadding || '',
        '--actions-padding': variables.actionsPadding || '',
        '--app-logo': variables.appLogo || '',
        '--logo-width': variables.logoWidth || '',
        '--logo-height': variables.logoHeight || '',
        '--bg-pattern': variables.bgPattern || '',
        '--header-pattern': variables.headerPattern || '',
    };
};

export const applyTwTheme = (theme: string): void => {
    const themeObject: TwMappedTheme = mapTwTheme(twThemes[theme]);
    if (!themeObject) return;

    const root = document.documentElement;

    Object.keys(themeObject).forEach((property) => {
        if (property === 'name') {
            return;
        }

        root.style.setProperty(property, themeObject[property]);
    });
};
