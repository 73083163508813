import { Paper } from '@mui/material';
import { CustomIconButton } from 'components/custom-controls';
import unpinIcon from 'components/app-assets/icon-unpinned.svg';
import unpinDarkIcon from 'components/app-assets/icon-unpinned-dark.svg';
import { useTranslation } from 'react-i18next';
import { useAppArgs } from 'custom-hooks/use-app-args';

type FormFieldsCollapsedPanelProps = {
    onPinButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export const FormFieldsCollapsedPanel = ({ onPinButtonClick }: FormFieldsCollapsedPanelProps) => {
    const { isStratus } = useAppArgs();
    const { t } = useTranslation();
    return (
        <Paper elevation={3} className="flex-col w-8 bg-theme-unpinpanel text-theme-unpinpanel-text">
            <CustomIconButton
                src={isStratus ? unpinDarkIcon : unpinIcon}
                tooltip={t('Pin to show Fields panel.')}
                onClick={onPinButtonClick}
            />
        </Paper>
    );
};
