import { Button, Divider, List, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import formIcon from 'components/app-header/assets/form-icon.svg';
import { AppLogo } from 'components/app-header/app-logo';
import { useQuery } from '@tanstack/react-query';
import { useHttpLoadFormsList } from 'custom-hooks/use-http-load-forms-list';
import { Base64 } from 'js-base64';
import { useAppLoadingApi } from 'custom-hooks/use-app-loading-api';
import { useSleep } from 'custom-hooks/use-sleep';
import { CustomIcon } from 'components/custom-controls';

export const TestForms = () => {
    const { loadFormsList } = useHttpLoadFormsList();
    const { hideLoading } = useAppLoadingApi();
    const { sleep } = useSleep();
    const { data, isLoading, isError } = useQuery({
        queryKey: ['load-forms-list'],
        queryFn: loadFormsList,
    });

    const newForm = () => {
        launchFormBuilder();
    };

    const editForm = (id: string) => {
        launchFormBuilder(id);
    };

    const launchFormBuilder = (formId = '') => {
        let qs = `tenant=sec&group=`;
        if (formId) qs += `&id=${formId}`;
        qs += `&redirect_url=${window.location.href}`;

        const url = `${window.location.origin}/?${encodeURIComponent(Base64.encode(qs))}`;
        window.location.replace(url);
    };

    sleep(1).then(() => {
        hideLoading();
    });

    return (
        <div className="flex flex-col h-screen">
            <div className="flex p-2 bg-theme-header-pattern bg-contain bg-no-repeat bg-[100%_center] bg-blend-soft-light">
                <AppLogo />
                <Divider orientation="vertical" className="bg-white ml-6 mr-6" />
                <div className="flex items-center justify-center">
                    <img src={formIcon} className="mr-3 mt-1" alt="Form Builder" />
                    <CustomIcon src={formIcon} className="mt-0.5 mr-1" />
                    <h1 className="text-2xl text-white">Forms Testing</h1>
                </div>
            </div>
            <div className="flex flex-1">
                <div className="flex flex-1 flex-col">
                    <div className="flex pl-4 pr-4 pt-4">
                        <Typography className="font-bold text-red-700">
                            Please go to the STC Portal and use Forms Management page to launch the Form Builder. If you
                            are here for testing, please remember this page is only for temporary testing and it will go
                            away soon.
                        </Typography>
                    </div>
                    <div className="flex">
                        <div className="p-4">
                            <Button variant="contained" onClick={newForm}>
                                Add New Form
                            </Button>
                        </div>
                    </div>
                    <Paper id="forms-list" elevation={3} className="flex flex-1 ml-4 mr-4 mt-1 mb-4 p-0">
                        <div className="flex-1 flex-col">
                            <div className="flex flex-row bg-theme-950">
                                <Typography variant="subtitle2" className="flex-1 ml-2.5 p-2 font-semibold text-white">
                                    Forms :: click on the Form name(s) to launch the editor
                                </Typography>
                            </div>
                            <div className="flex flex-col overflow-y-auto">
                                {isLoading && <div>Loading . . .</div>}
                                {isError && <div>Failed to retrieve forms list!</div>}
                                {data && (
                                    <List component="div" dense={true} disablePadding>
                                        {data.map((item) => (
                                            <ListItemButton
                                                key={item.id}
                                                onClick={() => editForm(item.id || '')}
                                                className="border-b border-solid border-gray-300">
                                                <ListItemText primary={item.title} />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                )}
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>
        </div>
    );
};
