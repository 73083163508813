import { AppLogo } from 'components/app-header/app-logo';
import { useAppLoadingApi } from 'custom-hooks/use-app-loading-api';
import { useAppArgs } from 'custom-hooks/use-app-args/use-app-args';
import { Typography, Button } from '@mui/material';
import { useSleep } from 'custom-hooks/use-sleep/use-sleep';
import { AppFooter } from 'components/app-footer/app-footer';
import { useTranslation } from 'react-i18next';

export const Unauthorized = () => {
    const { hideLoading } = useAppLoadingApi();
    const { redirectUrl } = useAppArgs();
    const { sleep } = useSleep();
    const { isStratus } = useAppArgs();
    const { t } = useTranslation();

    sleep(1).then(() => {
        hideLoading();
    });

    return (
        <div className="flex flex-col h-screen bg-theme-bg-pattern">
            <div className="flex p-2 bg-theme-header-pattern bg-contain bg-no-repeat bg-[100%_center] bg-blend-soft-light">
                <AppLogo />
            </div>
            <div className="flex flex-1">
                <div className="flex flex-1 flex-col">
                    <div className="flex pl-4 pr-4 pt-4">
                        <Typography className="font-bold text-red-700">
                            {isStratus
                                ? t(
                                      'Your session has expired. Please go to the Dispatcher Stratus portal to log in again.',
                                  )
                                : t(
                                      'Your session has expired. Please go to the Dispatcher ScanTrip Cloud portal to log in again.',
                                  )}
                        </Typography>
                    </div>
                    <div className="flex">
                        <div className="p-4">
                            <Button
                                variant="contained"
                                onClick={() => {
                                    window.location.replace(redirectUrl);
                                }}>
                                {isStratus
                                    ? t('Return to Dispatcher Stratus Portal')
                                    : t('Return to Dispatcher ScanTrip Cloud Portal')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <AppFooter />
        </div>
    );
};
