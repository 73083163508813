import { Typography } from '@mui/material';
import { AppTitle } from 'components/app-header';
import { useTranslation } from 'react-i18next';

export const AppInstructions = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col pl-pagetitle ml-default mt-default mr-default bg-theme-pagetitle text-theme-pagetitle-text">
            <AppTitle />
            <Typography variant="caption" className="ml-8 mb-2">
                {t('Create forms by dragging fields into the working area and editing settings.')}
            </Typography>
        </div>
    );
};
