import { useTranslation } from 'react-i18next';

export const FormFieldDropZonePreview = () => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col w-full h-20 border-2 border-theme-800 border-dashed rounded-lg bg-theme-100">
            <div className="flex-1 flex-row pt-5 pb-6">
                <p className="grid grid-cols-3 gap-4 mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold text-lg pl-4 place-self-start"></span>
                    <span className="font-semibold text-lg place-self-center">{t('Drop here')}</span>
                    <span className="font-semibold text-lg pr-4 place-self-end"></span>
                </p>
            </div>
        </div>
    );
};
