import { CustomIconButton } from 'components/custom-controls';
import { useFormHelpers } from 'custom-hooks/use-form-helpers';
import { useFormActions } from 'custom-hooks/use-form-actions';
import { useAppSnackbarApi } from 'custom-hooks/use-app-snackbar-api';
import iconValidate from './assets/icon_validate_white.svg';
import iconValidateDark from './assets/icon_validate_dark.svg';
import iconValidateHover from './assets/icon_validate.svg';
import { useTranslation } from 'react-i18next';
import { useAppArgs } from 'custom-hooks/use-app-args';

export const FormValidateButton = () => {
    const { validateForm } = useFormHelpers();
    const { setFormErrors } = useFormActions();
    const { showSuccessMessage, showErrorMessage } = useAppSnackbarApi();
    const { isStratus } = useAppArgs();
    const { t } = useTranslation();

    const onValidateButtonClick = () => {
        const groups = validateForm();
        const isValid = groups.length < 1;
        setFormErrors({
            show: !isValid,
            groups,
        });

        if (isValid)
            showSuccessMessage({
                title: t('Congratulations!'),
                text: t(
                    'You have successfully built a form. Continue editing your form or click on the "Save and Close" button to exit.',
                ),
            });
        else
            showErrorMessage({
                title: t('Almost there!'),
                text: t('Please address the errors listed below before continuing.'),
            });
    };

    return (
        <CustomIconButton
            src={isStratus ? iconValidateDark : iconValidate}
            srcHover={iconValidateHover}
            tooltip={t('Validate Form to find errors')}
            tooltipPlacement="right"
            onClick={onValidateButtonClick}
        />
    );
};
