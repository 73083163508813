import { usePlugins } from 'custom-hooks/use-plugins/use-plugins';
import { useFormFields } from 'custom-hooks/use-form-fields';
import { useFormTitle } from 'custom-hooks/use-form-title';
import { FormField } from 'contracts/forms/form-field';
import { FormErrorGroup } from 'contracts/form-state/form-errors';
import formIcon from 'components/app-header/assets/form-icon.svg';
import { useTranslation } from 'react-i18next';

export const useFormHelpers = () => {
    const { getProvider, getClonedField, validateField } = usePlugins();
    const fields = useFormFields();
    const formTitle = useFormTitle();
    const { t } = useTranslation();

    const getSuggestedFieldVarName = (fieldType: string) => {
        const provider = getProvider(fieldType);
        const varPrefix = fieldType === 'pagebreak' ? 'page' : fieldType;
        const similarFields = fields.filter((f) => f.type === fieldType);
        let count = 1,
            index = 0;
        let suggestedVarName = '';
        do {
            const tmpVarname = (suggestedVarName = `${varPrefix}${count}`);
            ++count;
            index = similarFields.findIndex((f) => provider.getVariable(f).toLocaleLowerCase() === tmpVarname);
        } while (index >= 0);

        return suggestedVarName;
    };

    const getFieldIndex = (id: string) => {
        return fields.findIndex((f) => f.id === id);
    };

    const cloneField = (field: FormField) => {
        return getClonedField(field, getSuggestedFieldVarName(field.type));
    };

    const validateForm = (): FormErrorGroup[] => {
        const errorGroups: FormErrorGroup[] = [];

        if (!formTitle || !formTitle.trim()) {
            errorGroups.push({
                icon: formIcon,
                title: t('Form'),
                items: [t('Form name is required.')],
            });
        }
        fields.forEach((field, index) => {
            const provider = getProvider(field.type);
            const fieldVar = provider.getVariable(field);
            const group: FormErrorGroup = {
                id: field.id,
                title: fieldVar ? `${provider.title} (${fieldVar})` : provider.title,
                icon: provider.icon,
                items: [],
            };
            const fieldStatus = validateField(field);
            if (!fieldStatus.valid && fieldStatus.errors) group.items = fieldStatus.errors;
            if (isDuplicateVar(fieldVar))
                group.items.push(
                    t('Another field exists with the same variable name. Duplicate variable names are not allowed.'),
                );
            if (field.type === 'pagebreak' && (index === fields.length - 1 || fields[index + 1].type === 'pagebreak'))
                group.items.push(t('This page looks empty. Please add one or more form fields after this page break.'));
            if (group.items && group.items.length > 0) errorGroups.push(group);
        });

        return errorGroups;
    };

    const getAllMetadataKeys = (): string => {
        const metadataKeys: string[] = [];

        fields.forEach((field) => {
            const provider = getProvider(field.type);
            const metaVar = provider.getMatadataVariable(field);
            if (metaVar) metadataKeys.push(metaVar);
        });

        return metadataKeys.join(',');
    };

    const isDuplicateVar = (variable: string) => {
        let count = 0;
        fields.every((field) => {
            const provider = getProvider(field.type);
            if (variable === provider.getVariable(field)) ++count;
            return count > 1 ? false : true;
        });
        return count > 1;
    };

    return {
        getSuggestedFieldVarName,
        getFieldIndex,
        cloneField,
        validateForm,
        getAllMetadataKeys,
    } as const;
};
