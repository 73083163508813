import { Typography } from '@mui/material';
import { FormFieldDropdownOption } from './form-field-dropdown-config';
import { useTranslation } from 'react-i18next';

type DropdownOptionsItemProps = FormFieldDropdownOption & {
    className?: string;
};

export const DropdownOptionsItem = ({ label, value, isDefault, className = '' }: DropdownOptionsItemProps) => {
    const { t } = useTranslation();
    return (
        <div className={`grid grid-cols-2 mb-1 ${isDefault ? 'bg-theme-100' : ''} ${className}`}>
            <div className="ml-2 truncate">
                {label && <Typography variant="caption">{label}</Typography>}
                {!label && (
                    <Typography variant="caption" className=" text-transparent">
                        {t('Blank')}
                    </Typography>
                )}
            </div>

            <div className="ml-1 truncate">
                {value && <Typography variant="caption">{value}</Typography>}
                {!value && (
                    <Typography variant="caption" className=" text-transparent">
                        {t('Blank')}
                    </Typography>
                )}
            </div>
        </div>
    );
};
