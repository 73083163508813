import React from 'react';

type CustomDialogTitleProps = {
    children: React.ReactNode;
};

export const CustomDialogTitle = ({ children }: CustomDialogTitleProps) => {
    return (
        <div className="flex-1 p-2 border-b border-solid border-gray-300 rounded-t bg-theme-titlebar text-theme-titlebar-text ">
            {children}
        </div>
    );
};
