import { FormValidateButton } from './form-validate-button';
//import { FormDownloadButton } from './form-download-button';
//import { FormMetadataButton } from './form-metadata-button';

export const AppSidebar = () => {
    return (
        <div className="flex-col w-10 pt-10 mt-sidebar-top space-y-3 bg-theme-sidebar text-theme-sidebar-text">
            {/* <FormDownloadButton /> */}
            <FormValidateButton />
            {/* <FormMetadataButton /> */}
        </div>
    );
};
