import { CustomIconButton } from 'components/custom-controls';
import { useFormActions } from 'custom-hooks/use-form-actions';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

export const FormErrorsCloseButton = () => {
    const { setShowFormErrors } = useFormActions();
    const { t } = useTranslation();

    const onCloseButtonClick = () => {
        setShowFormErrors(false);
    };

    return (
        <CustomIconButton tooltip={t('Close Error List')} onClick={onCloseButtonClick}>
            <CloseIcon className="text-theme-titlebar-text" />
        </CustomIconButton>
    );
};
