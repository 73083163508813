export const stratusTwTheme = {
    color50: '#f2f2fb',
    color100: '#f2eff9',
    color200: '#d5d4f1',
    color300: '#ddd5f2',
    color400: '#a89ddc',
    color500: '#9584d0',
    color600: '#7d61bc',
    color700: '#745aa8',
    color800: '#623DA2',
    color900: '#231231',
    color950: '#262626',
    sidebar: '#FFFFFF66',
    sidebarText: '#323232',
    sidebarTopMargin: '16px',
    headerText: '#323232',
    pagetitle: '#FFFFFF73',
    pagetitleText: '#323232',
    pagetitlePadding: '24px',
    pinpanel: '#FFFFFFCC',
    pinpanelText: '#323232',
    unpinpanel: '#FFFFFF66',
    unpinpanelText: '#323232',
    errorspanel: '#FFFFFFCC',
    errorspanelText: '#323232',
    titlebar: '#e3e3e3',
    titlebarText: '#323232',
    footer: '#000000',
    footerText: '#ffffff',
    defaultMargin: '16px',
    extraMargin: '20px',
    defaultPadding: '16px',
    actionsPadding: '16px',
    appLogo: "url('/assets/images/logo-stratus.svg')",
    logoWidth: '176px',
    logoHeight: '44px',
    bgPattern: "url('/assets/images/bg-stratus.jpg')",
    headerPattern: 'linear-gradient(to right, #FFFFFF73 99%, #FFFFFF73 1%)',
};
