import { CustomIcon } from 'components/custom-controls';
import formIcon from './assets/form-icon.svg';

export const AppTitle = () => {
    return (
        <div className="flex items-center">
            <CustomIcon src={formIcon} className="mt-0.5 mr-1" />
            <h1 className=" text-xl font-bold  text-theme-pagetitle-text">Form Builder</h1>
        </div>
    );
};
