import { Paper, Typography } from '@mui/material';
import { useFormActions } from 'custom-hooks/use-form-actions';
import { usePlugins } from 'custom-hooks/use-plugins';
import { FormPropertiesEmptyStage } from './form-properties-empty-stage';
import { useFormSelectedField } from 'custom-hooks/use-form-selected-field';
import { CustomIconButton } from 'components/custom-controls';
import pinIcon from 'components/app-assets/icon-pinned.svg';
import { useTranslation } from 'react-i18next';

export type FormPropertiesExpandedPanelProps = {
    onPinButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export const FormPropertiesExpandedPanel = ({ onPinButtonClick }: FormPropertiesExpandedPanelProps) => {
    const selectedField = useFormSelectedField();
    const { updateField, selectField } = useFormActions();
    const { getProvider, getFieldProperties } = usePlugins();
    const { t } = useTranslation();

    if (null === selectedField) {
        return <FormPropertiesEmptyStage onPinButtonClick={onPinButtonClick} />;
    }

    const provider = getProvider(selectedField.type);
    const FieldPropertiesComponent = getFieldProperties(selectedField.type);

    const onUnpinButtonClick = (event: React.MouseEvent<HTMLElement>) => {
        onPinButtonClick(event);
        selectField(selectedField.id);
    };

    return (
        <Paper elevation={3} className="flex flex-col w-80 overflow-hideen bg-transparent">
            <div className="flex flex-row bg-theme-pinpanel text-theme-pinpanel-text">
                <Typography variant="subtitle2" className="flex-1 ml-4 mt-2 font-semibold">
                    {t('Field Properties')}
                </Typography>
                <CustomIconButton
                    src={pinIcon}
                    tooltip={t('Unpin to hide Field Properties panel.')}
                    onClick={onUnpinButtonClick}
                />
            </div>
            <div className="flex flex-col h-screen overflow-y-auto bg-white">
                <div className="ml-4 mr-4 mt-2 mb-2 flex-col">
                    <div className="flex flex-row">
                        <img src={provider.icon} alt={provider.title} className="mr-2 h-5" />
                        <Typography variant="caption">{provider.title}</Typography>
                    </div>
                    <div className="flex-col mt-6">
                        <FieldPropertiesComponent updateField={updateField} field={selectedField} />
                    </div>
                </div>
            </div>
        </Paper>
    );
};
