import { AppLogo } from './app-logo';
import { AppUserInfo } from './app-user-info';

export const AppHeader = () => {
    return (
        <div className="flex p-2 bg-theme-header-pattern bg-contain bg-no-repeat bg-[100%_center] bg-blend-soft-light">
            <AppLogo />
            <AppUserInfo />
        </div>
    );
};

export * from './app-logo';
export * from './app-title';
export * from './app-user-info';
